exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-brochure-download-jsx": () => import("./../../../src/pages/brochure-download.jsx" /* webpackChunkName: "component---src-pages-brochure-download-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-privacy-jsx": () => import("./../../../src/pages/legal/privacy.jsx" /* webpackChunkName: "component---src-pages-legal-privacy-jsx" */),
  "component---src-pages-legal-tos-jsx": () => import("./../../../src/pages/legal/tos.jsx" /* webpackChunkName: "component---src-pages-legal-tos-jsx" */),
  "component---src-pages-shoppers-about-js": () => import("./../../../src/pages/shoppers/about.js" /* webpackChunkName: "component---src-pages-shoppers-about-js" */),
  "component---src-pages-shoppers-jsx": () => import("./../../../src/pages/shoppers.jsx" /* webpackChunkName: "component---src-pages-shoppers-jsx" */)
}

